import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ParsedExpenseFormData } from "../../types/expenses";
import { getCurrencySymbol } from "../../utils/currencies";

interface ParsedExpensesDialogProps {
  open: boolean;
  expenses: ParsedExpenseFormData[];
  loading: boolean;
  onClose: () => void;
  onConfirm: (selectedExpenses: ParsedExpenseFormData[]) => void;
}

const ParsedExpensesDialog: React.FC<ParsedExpensesDialogProps> = ({
  open,
  expenses,
  loading,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const [selectedExpenses, setSelectedExpenses] = useState<any[]>([]);

  useEffect(() => {
    setSelectedExpenses(expenses);
  }, [expenses]);

  const handleToggle = (expense: any) => () => {
    const currentIndex = selectedExpenses.indexOf(expense);
    const newChecked = [...selectedExpenses];

    if (currentIndex === -1) {
      newChecked.push(expense);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedExpenses(newChecked);
  };

  const handleConfirm = () => {
    onConfirm(selectedExpenses);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t("expenses.parsedExpensesDialog.title")}
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {expenses.map((expense, index) => (
            <ListItem
              key={index}
              onClick={handleToggle(expense)}
              sx={{ cursor: "pointer" }}
            >
              <Checkbox
                edge="start"
                checked={selectedExpenses.indexOf(expense) !== -1}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText
                primary={`Truck: ${
                  expense.truck?.license_plate || "N/A"
                }, Total Net: ${expense.net_amount} ${getCurrencySymbol(
                  expense.currency
                )}, Description: ${expense.description}, Country: ${
                  expense.country
                }, VAT Rate: ${expense.vat_rate}`}
                secondary={`Transaction Time: ${expense.date}, Category: ${expense.category}`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t("common.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary" disabled={loading}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParsedExpensesDialog;
