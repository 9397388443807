import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link, Navigate, useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import EmployeeBaseDetailsForm from "./EmployeeBaseDetailsForm";
import { useToast } from "../../hooks/useToast";
import {
  EmployeeDetails,
  EmployeeDocumentFormData,
} from "../../types/employees";
import EmployeeDocuments from "./Documents/EmployeeDocuments";
import EmployeePayments from "./Payments/Payments";
import { useAuth } from "../../contexts/AuthContext";
import { hasAccess } from "../../utils/permissions";

const MyEmployeeDetailContainer = () => {
  const { account } = useAuth();

  const { t } = useTranslation();
  const { employeeId } = useParams();
  const axios = useAxios();
  const [employee, setEmployee] = useState<EmployeeDetails | null>(null);
  const toast = useToast();
  useEffect(() => {
    if (!employeeId) return;
    axios
      .get(`/my-fleet/employees/${employeeId}`)
      .then((response) => {
        setEmployee(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [employeeId]);

  const onSubmit = (data: any) => {
    axios.patch(`/my-fleet/employees/${employeeId}/`, data).then(() => {
      toast({
        message: t("employeeDetail.toast.success"),
        type: "success",
      });
    });
  };

  const onUpdateDocument = async (documentId: number, data: any) => {
    const response = await axios.patch(
      `/my-fleet/employees/${employeeId}/documents/${documentId}/`,
      data
    );
    setEmployee((prevEmployee) => {
      if (!prevEmployee) return null;
      return {
        ...prevEmployee,
        documents: prevEmployee.documents.map((doc) =>
          doc.id === documentId ? response.data : doc
        ),
      };
    });
    toast({
      message: "Document updated",
      type: "success",
    });
  };
  const onAddDocument = async (document: EmployeeDocumentFormData) => {
    const response = await axios.post(
      `/my-fleet/employees/${employeeId}/documents/`,
      document
    );
    setEmployee((prevEmployee) => {
      if (!prevEmployee) return null;
      return {
        ...prevEmployee,
        documents: [...prevEmployee.documents, response.data],
      };
    });
    toast({
      message: "Document added",
      type: "success",
    });
  };

  const onDeleteDocument = async (fileId: number) => {
    try {
      const response = await axios.delete(
        `/my-fleet/employees/${employeeId}/documents/${fileId}/`
      );
      if (response.status === 204) {
        setEmployee((prevEmployee) => {
          if (!prevEmployee) return null;
          return {
            ...prevEmployee,
            documents: prevEmployee.documents.filter(
              (doc) => doc.id !== fileId
            ),
          };
        });
        toast({
          message: "Document deleted",
          type: "success",
        });
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const onUpdateEmployeePayment = async (data: any) => {
    const response = await axios.patch(
      `/my-fleet/employees/${employeeId}/`,
      {
        payment_payload: data,
      }
    );
    setEmployee((prevEmployee) => {
      if (!prevEmployee) return null;
      return {
        ...prevEmployee,
        payments: response.data,
      };
    });
    toast({
      message: "Payment added",
      type: "success",
    });
  };

  const onAddEmployeePayment = async (data: any) => {
    const response = await axios.post(
      `/my-fleet/employees/${employeeId}/payments/`,
      data
    );
    setEmployee((prevEmployee) => {
      if (!prevEmployee) return null;

      return {
        ...prevEmployee,
        payment_history: [...prevEmployee.payment_history, response.data],
      };
    });
    toast({
      message: "Payment added",
      type: "success",
    });
  };

  const onDeleteEmployeePayment = async (paymentId: number) => {
    try {
      const response = await axios.delete(
        `/my-fleet/employees/${employeeId}/payments/${paymentId}/`
      );
      if (response.status === 204) {
        setEmployee((prevEmployee) => {
          if (!prevEmployee) return null;
          return {
            ...prevEmployee,
            payment_history: prevEmployee.payment_history.filter(
              (payment) => payment.id !== paymentId
            ),
          };
        });
        toast({
          message: "Payment deleted",
          type: "success",
        });
      } else {
        console.error("Upload failed:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  if (!account) return <Navigate to="/sign-in" />;

  return employee ? (
    <div className="p-4 text-black">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">{t("breadcrumb.home")}</Link>
        <Link to="/my-fleet">{t("breadcrumb.myFleet")}</Link>
        <Typography color="text.primary">
          {t("employeeDetail.title")} - {employee.full_name}
        </Typography>
      </Breadcrumbs>

      <Tabs>
        <TabList>
          <Tab>{t("employeeDetail.tabs.general")}</Tab>

          {hasAccess(account.role, "employeeDocuments", "read") && (
            <>
              <Tab>{t("employeeDetail.tabs.documents")}</Tab>
              <Tab>{t("employeeDetail.tabs.payments")}</Tab>
            </>
          )}
        </TabList>

        <TabPanel>
          <EmployeeBaseDetailsForm employee={employee} onSubmit={onSubmit} />
        </TabPanel>
        {hasAccess(account.role, "driversDocuments", "read") && (
          <>
            <TabPanel>
              <EmployeeDocuments
                employee={employee}
                onUpdateDocument={onUpdateDocument}
                onAddDocument={onAddDocument}
                onDeleteDocument={onDeleteDocument}
              />
            </TabPanel>
            <TabPanel>
              <EmployeePayments
                employee={employee}
                onUpdateEmployeePayment={onUpdateEmployeePayment}
                onAddEmployeePayment={onAddEmployeePayment}
                onDeleteEmployeePayment={onDeleteEmployeePayment}
              />
            </TabPanel>
          </>
        )}
      </Tabs>
    </div>
  ) : (
    <CircularProgress />
  );
};

export default MyEmployeeDetailContainer;
