import { Button, Dialog, MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import { OrderTemplate, TemplateBase } from "../../types/templates";
import TemplatePreview from "../Template/TemplatePreview";
import { useToast } from "../../hooks/useToast";
import { format } from "date-fns-tz";
import { EmailLog } from "../../types/email";
import { OrderInfo } from "../../types/orders";

interface OrderMailingProps {
  order: OrderInfo;
}

const OrderMailing: React.FC<OrderMailingProps> = ({ order }) => {
  const { t } = useTranslation();
  const [orderTemplates, setOrderTemplates] = useState<OrderTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateBase | null>(
    null
  );
  const [htmlPreview, setHtmlPreview] = useState<string | null>(null);
  const [htmlPreviewSubject, setHtmlPreviewSubject] = useState<string | null>(
    null
  );
  const [email, setEmail] = useState<string | null>(null);
  const axios = useAxios();
  const toast = useToast();
  const [selectedEmailLog, setSelectedEmailLog] = useState<EmailLog | null>(
    null
  );

  useEffect(() => {
    axios.get("/template-manager/orders").then((response) => {
      setOrderTemplates(response.data);
      if (response.data.length > 0) {
        setSelectedTemplate(response.data[0]);
      }
    });
  }, []);

  const onClose = () => {
    setHtmlPreview(null);
    setHtmlPreviewSubject(null);
    setEmail(null);
    setSelectedEmailLog(null);
  };

  const onPreview = () => {
    if (!selectedTemplate) {
      return;
    }
    axios
      .post(`/orders/${order.id}/mail/preview/${selectedTemplate.id}/`)
      .then((response) => {
        setHtmlPreview(response.data.message);
        setHtmlPreviewSubject(response.data.title);
        setEmail(response.data.email);
      });
  };

  const sendEmail = () => {
    if (!selectedTemplate || !email) {
      return;
    }
    axios
      .post(`/orders/${order.id}/mail/${selectedTemplate.id}/`, {
        email,
      })
      .then(() => {
        onClose();
        toast({
          message: t("orders.orderMailing.emailSent"),
          type: "success",
        });
      });
  };

  console.log(order);
  return (
    <div className="flex flex-row gap-2">
      <div className="flex flex-col gap-2 w-1/2 max-h-96 overflow-y-auto">
        {order.emails.map((email) => (
          <div
            key={email.email_log.uuid}
            className="border rounded-lg shadow-sm p-4 mb-4 bg-white hover:shadow-md transition-shadow duration-300"
          >
            {/* Grid Layout for Email Info */}
            <div className="grid grid-cols-2 gap-4">
              {/* Recipient and Sent Time */}
              <div>
                <p className="font-bold text-gray-700">
                  {t("orders.orderMailing.recipient")}
                </p>
                <p className="text-sm text-gray-500">
                  {email.email_log.recipient} -{" "}
                  {email.email_log.receiver_shipper_name}
                </p>
              </div>
              <div>
                <p className="font-bold text-gray-700">
                  {t("orders.orderMailing.sentAt")}
                </p>
                <p className="text-sm text-gray-500">
                  {email.email_log.sender_company_name} -{" "}
                  {email.email_log.user_username}
                </p>
              </div>
              <div>
                <p className="font-bold text-gray-700">
                  {t("orders.orderMailing.sentAt")}
                </p>
                <p className="text-sm text-gray-500">
                  {format(
                    new Date(email.email_log.sent_at),
                    "yyyy-MM-dd HH:mm:ss"
                  )}
                </p>
              </div>

              {/* Opened Status */}
              <div>
                <p className="font-bold text-gray-700">
                  {t("orders.orderMailing.opened")}
                </p>
                <p
                  className={`text-sm ${
                    email.email_log.opened ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {email.email_log.opened
                    ? t("orders.orderMailing.opened")
                    : t("orders.orderMailing.notOpened")}
                </p>
              </div>

              {/* Subject and Status Icons */}
              <div className="flex items-center space-x-2">
                <p className="font-bold text-gray-700">
                  {t("orders.orderMailing.subject")}
                </p>
                <p className="text-sm text-gray-500">
                  {email.email_log.subject}
                </p>
              </div>

              {/* Delivery Status */}
              <div className="flex items-center space-x-2">
                <p className="font-bold text-gray-700">
                  {t("orders.orderMailing.deliveryStatus")}
                </p>
                <span
                  className={
                    email.email_log.delivered
                      ? "text-green-500"
                      : "text-red-500"
                  }
                >
                  {email.email_log.delivered ? "✔️" : "❌"}
                </span>
              </div>

              {/* Clicked and Spam Complaint */}
              <div className="flex items-center space-x-2">
                <p className="font-bold text-gray-700">
                  {t("orders.orderMailing.clicked")}
                </p>
                <span
                  className={
                    email.email_log.clicked ? "text-green-500" : "text-red-500"
                  }
                >
                  {email.email_log.clicked ? "✔️" : "❌"}
                </span>
              </div>

              <div className="flex items-center space-x-2">
                <p className="font-bold text-gray-700">
                  {t("orders.orderMailing.spamComplaint")}
                </p>
                <span
                  className={
                    email.email_log.spam_complaint
                      ? "text-red-500"
                      : "text-green-500"
                  }
                >
                  {email.email_log.spam_complaint ? "⚠️" : "✔️"}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <Button
                  variant="contained"
                  onClick={() => {
                    setSelectedEmailLog(email.email_log);
                  }}
                >
                  {t("orders.orderMailing.preview")}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col items-center gap-4 py-4 w-1/2">
        <h1 className="text-2xl font-bold text-gray-700">
          {t("orders.orderMailing.sendEmail")}
        </h1>
        <TextField
          label={t("orders.orderMailing.template")}
          select
          fullWidth
          variant="outlined"
          value={selectedTemplate?.id || ""}
          onChange={(e) =>
            setSelectedTemplate(
              orderTemplates.find(
                (template) => template.id === parseInt(e.target.value)
              ) || null
            )
          }
        >
          {orderTemplates.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" onClick={onPreview}>
          {t("orders.orderMailing.preview")}
        </Button>
        {htmlPreview && htmlPreviewSubject && (
          <TemplatePreview
            htmlPreview={htmlPreview}
            htmlPreviewSubject={htmlPreviewSubject}
          />
        )}
        {email && (
          <TextField
            label={t("orders.orderMailing.email")}
            value={email}
            fullWidth
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
        )}
        <Button variant="contained" onClick={sendEmail}>
          {t("orders.orderMailing.send")}
        </Button>
      </div>

      <Dialog open={selectedEmailLog !== null} onClose={() => onClose()}>
        {selectedEmailLog && (
          <TemplatePreview
            htmlPreview={selectedEmailLog.body}
            htmlPreviewSubject={selectedEmailLog.subject}
          />
        )}
        {selectedEmailLog && (
          <div className="p-4">
            {" "}
            <p className="font-bold text-gray-700">
              {t("orders.orderMailing.body")}
            </p>
            <p className="text-sm text-gray-500">{selectedEmailLog.body_txt}</p>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default OrderMailing;
