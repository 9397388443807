export type Driver = {
  id: number;
  email: string;
  full_name: string;
  first_name: string;
  last_name: string;
  phone: string;
  status: string;
};

export type DriverPaymentHistory = {
  id: number;
  date: string;
  taxable_net_pay: number;
  net_pay: number;
  gross_pay: number;
  vat_amount: number;
  date_start: string;
  date_end: string;
  payment_date: string;
  notes: string;
};

export type DriverPayment = {
  base_taxable_net_daily_pay: number;
  base_net_daily_pay: number;
  currency: string;
  employment_type: string;
  vat_rate: number;
};

export type DriverDetails = Driver & {
  payment_info?: DriverPayment;
  payment_history: DriverPaymentHistory[];
  documents: DriverDocument[];
};

export interface DriverDocument {
  id: number;
  file: string;
  description: string;
  created_at: string;
  type: string;
  end_date: string | null;
}

export type DriverDocumentFormData = Omit<
  DriverDocument,
  "id" | "created_at" | "file"
>;

export const DRIVER_DOCUMENT_TYPES = [
  "id_card",
  "passport",
  "driver_license",
  "medical_certificate",
  "adr_certificate",
  "tachograph_card",
  "other",
];

export const DRIVER_PAYMENT_CATEGORY_TYPES = [
  "advance",
  "bonus",
  "salary",
  "other",
];

export const DEFAULT_DRIVER_PAYMENT = "salary";

export const DRIVER_STATUS_FILTER_OPTIONS = [
  "active",
  "candidate",
  "fired",
  "resigned",
];
