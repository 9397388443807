import { useEffect, useState } from "react";
import { TemplateBase } from "../../types/templates";
import useAxios from "../../hooks/useAxios";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import TemplateEditor from "./TemplateEditor";
import TemplatePreview from "./TemplatePreview";
import CreateTemplateForm from "./CreateTemplateForm";

const InvoiceDueTemplates = () => {
  const [invoiceDueTemplates, setInvoiceDueTemplates] = useState<
    TemplateBase[]
  >([]);
  const [htmlPreview, setHtmlPreview] = useState<string | null>(null);
  const [htmlPreviewSubject, setHtmlPreviewSubject] = useState<string | null>(
    null
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const axios = useAxios();

  useEffect(() => {
    axios.get("/template-manager/invoice-due").then((response) => {
      setInvoiceDueTemplates(response.data);
    });
  }, []);

  const { t } = useTranslation();
  const [openedTemplate, setOpenedTemplate] = useState<TemplateBase | null>(
    null
  );

  const handleSave = (data: any, templateId: number) => {
    axios
      .patch(`/template-manager/invoice-due/${templateId}/`, data)
      .then(() => {
        onCloseTemplate();
        setInvoiceDueTemplates((prev) =>
          prev.map((template) =>
            template.id === templateId ? { ...template, ...data } : template
          )
        );
      });
  };

  const onPreview = (data: any, templateId: number) => {
    axios
      .post(`/template-manager/invoice-due/${templateId}/preview/`, data)
      .then((response) => {
        setHtmlPreview(response.data.message);
        setHtmlPreviewSubject(response.data.title);
      });
  };

  const onCreate = (data: any) => {
    axios.post("/template-manager/invoice-due/", data).then((response) => {
      setInvoiceDueTemplates((prev) => [...prev, response.data]);
      setCreateModalOpen(false);
      onCloseTemplate();
    });
  }

  const onCloseTemplate = () => {
    setOpenedTemplate(null);
    setHtmlPreview(null);
    setHtmlPreviewSubject(null);
  };

  return (
    <div className="p-2">
      <Button onClick={() => setCreateModalOpen(true)} variant="contained">
        {t("templates.orders.createTemplate")}
      </Button>
      <div className="mt-4 border-t border-b border-gray-200">
        {invoiceDueTemplates.map((template) => (
          <div key={template.id} className="flex justify-between items-center border-b border-gray-200 p-2">
            <h2>{template.name}</h2>
            <Button onClick={() => setOpenedTemplate(template)}>
              {t("common.view")}
            </Button>
          </div>
        ))}
      </div>

      <Dialog
        open={openedTemplate !== null}
        onClose={() => onCloseTemplate()}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t("templates.invoiceDue.templateDetails")}</DialogTitle>
        <DialogContent>
          {openedTemplate && (
            <TemplateEditor
              template={openedTemplate}
              variables={[
                "shipper",
                "payment_amount",
                "payment_currency",
                "order_number",
                "invoice_number",
                "sender_name",
                "sender_signature",
              ]}
              placeholder={t("templates.invoiceDue.placeholder")}
              subjectPlaceholder={t("templates.invoiceDue.subjectPlaceholder")}
              handleSave={(data) => handleSave(data, openedTemplate.id)}
              onPreview={(data) => onPreview(data, openedTemplate.id)}
            />
          )}
          {/* Render the preview if there is HTML */}
          {htmlPreview && htmlPreviewSubject && (
            <TemplatePreview
              htmlPreview={htmlPreview}
              htmlPreviewSubject={htmlPreviewSubject}
            />
          )}
          <DialogActions>
            <Button onClick={() => onCloseTemplate()} color="primary">
              {t("common.cancel")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{t("templates.orders.createTemplate")}</DialogTitle>
        <DialogContent>
          <CreateTemplateForm
            variables={[
              "shipper",
              "payment_amount",
              "payment_currency",
              "order_number",
              "invoice_number",
              "sender_name",
              "sender_signature",
            ]}
            onSave={onCreate}
          />
          <DialogActions>
            <Button onClick={() => setCreateModalOpen(false)} color="primary">
              {t("common.cancel")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InvoiceDueTemplates;
