import RouteSummary from "../../RouteSummary";
import { Route } from "../../../../types/routes";
import { getCurrencySymbol } from "../../../../utils/currencies";
import ExcludeInput from "./ExcludeInput";
import { useMap } from "../../../../contexts/MapContext";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

interface RouteAndAlternativesProps {
  setHoveredRoute: (route: Route) => void;
  onLeaveHoverRoute: (route: Route) => void;
  setActiveRoute: (route: Route) => void;
}

const RouteAndAlternatives: React.FC<RouteAndAlternativesProps> = ({
  setHoveredRoute,
  onLeaveHoverRoute,
  setActiveRoute,
}) => {
  const { t } = useTranslation();
  const {
    routeAlternatives,
    activeRoute,
    hoveredRoute,
    ordersToAddToRoute,
    activeSection,
    selectedTruck,
    onAssignRoute,
    onRecalculateRoute,
    onMarkRouteAsCompleted,
  } = useMap();

  if (!activeRoute) return null;

  return (
    <div className="bg-zinc-900 flex absolute bottom-0 left-full ml-16 mb-4">
      <div className="flex flex-col justify-between items-center p-2 space-y-2 z-40 text-secondary w-full">
        <div className="w-full border-b-2">
          <div className="grid grid-cols-4 px-4">
            <div>{t("routes.route")}</div>
            <div>{t("routes.totalTolls")}</div>
            <div>{t("routes.totalDistance")}</div>
            <div>{t("routes.totalDuration")}</div>
          </div>
        </div>
        {routeAlternatives
          .filter((route) => activeRoute.id !== route.id)
          .map((route) => (
            <RouteSummary
              name={`Route ${route.index + 1} - ${route.mode}`}
              onSetActiveRoute={() => setActiveRoute(route)}
              onHoverRoute={() => setHoveredRoute(route)}
              onLeaveHoverRoute={() => onLeaveHoverRoute(route)}
              key={route.id}
              route={route}
              activeRoute={activeRoute}
              hoveredRoute={hoveredRoute}
            />
          ))}
        <div className="flex flex-col w-full px-2 bg-zinc-800 shadow-md rounded-lg text-xs">
          <div className="flex justify-between items-center border-b pb-2">
            <div className="font-medium text-secondary">
              {t("routes.selectedRoute")}
            </div>
            <div className="text-center">
              <div className="">{t("routes.totalTolls")}</div>
              <div className="font-semibold ">
                {activeRoute.summary.total_tolls.toFixed(2)}€
              </div>
            </div>
            <div className="text-center">
              <div className="">{t("routes.totalDistance")}</div>
              <div className="font-semibold ">
                {(activeRoute.summary.distance / 1000).toFixed(1)} km
              </div>
            </div>
            <div className="text-center">
              <div className="">{t("routes.totalDuration")}</div>
              <div className="font-semibold ">
                {(activeRoute.summary.duration / 3600).toFixed(1)}h
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-6 justify-between my-2">
              <div className="text-center">
                <div className="text-xs ">{t("routes.totalFuel")}</div>
                <div className="text-xs font-semibold ">
                  {activeRoute.summary.consumption.toFixed(2)} l
                </div>
              </div>
              <div className="text-center">
                <div className="text-xs ">{t("routes.grossPricePerKm")}</div>
                <div className="text-xs font-semibold ">
                  {activeRoute.summary.price_per_km.toFixed(2)}
                  {getCurrencySymbol(activeRoute.summary.currency)}
                </div>
              </div>
              <div className="text-center">
                <div className="">{t("routes.netIncomePerKm")}</div>
                <div className="font-semibold ">
                  {activeRoute.summary.net_income_per_km.toFixed(2)}
                  {getCurrencySymbol(activeRoute.summary.currency)}
                </div>
              </div>
              <div className="text-center">
                <div className="">{t("routes.grossPrice")}</div>
                <div className="font-semibold ">
                  {activeRoute.summary.price.toFixed(2)}
                  {getCurrencySymbol(activeRoute.summary.currency)}
                </div>
              </div>
              <div className="text-center">
                <div className="">{t("routes.netIncome")}</div>
                <div className="font-semibold ">
                  {activeRoute.summary.net_income.toFixed(2)}
                  {getCurrencySymbol(activeRoute.summary.currency)}
                </div>
              </div>
              <div className="text-center">
                <div className="">{t("routes.fuelCost")}</div>
                <div className="font-semibold ">
                  {activeRoute.summary.fuel_cost.toFixed(2)}
                  {getCurrencySymbol(activeRoute.summary.currency)}
                </div>
              </div>
              <div className="text-center">
                <div className="">{t("routes.operationalCost")}</div>
                <div className="font-semibold ">
                  {activeRoute.summary.operational_cost.toFixed(2)}
                  {getCurrencySymbol(activeRoute.summary.currency)}
                </div>
              </div>
              <div className="text-center">
                <div className="">{t("routes.leasingCost")}</div>
                <div className="font-semibold ">
                  {activeRoute.summary.leasing_cost.toFixed(2)}
                  {getCurrencySymbol(activeRoute.summary.currency)}
                </div>
              </div>
              <div className="text-center">
                <div className="">{t("routes.driverCost")}</div>
                <div className="font-semibold ">
                  {activeRoute.summary.driver_cost.toFixed(2)}
                  {getCurrencySymbol(activeRoute.summary.currency)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-between">
          {activeSection && (
            <div className="flex justify-between items-center w-1/2 p-4 bg-white shadow-md rounded-lg self-start">
              <div className="font-medium text-gray-700">Section</div>
              <div className="text-center">
                <div className="">Time</div>
                <div className="font-semibold ">
                  {(activeSection.summary.duration / 3600).toFixed(1)}h
                </div>
              </div>
              <div className="text-center">
                <div className="">Distance</div>
                <div className="font-semibold ">
                  {(activeSection.summary.length / 1000).toFixed(1)} km
                </div>
              </div>
            </div>
          )}
          {!activeRoute.is_selected_route &&
            selectedTruck &&
            ordersToAddToRoute.length === 0 && (
              <Button
                variant="contained"
                onClick={() => {
                  onAssignRoute(activeRoute);
                }}
              >
                {t("routes.assign")}
              </Button>
            )}
          {activeRoute.is_selected_route && ordersToAddToRoute.length > 0 && (
            <div className="flex gap-2">
              <ExcludeInput />
              <Button
                className="font-bold"
                variant="contained"
                onClick={onRecalculateRoute}
              >
                {t("routes.recalculate")}
              </Button>
            </div>
          )}
          {activeRoute.is_selected_route && ordersToAddToRoute.length > 0 && (
            <Button
              className="font-bold"
              variant="contained"
              onClick={() => onMarkRouteAsCompleted(activeRoute)}
            >
              {t("routes.complete")}
            </Button>
          )}
        </div>
      </div>{" "}
    </div>
  );
};

export default RouteAndAlternatives;
