import { useCallback, useMemo, useRef } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, Slate } from "slate-react";
import { Transforms, createEditor, Descendant } from "slate";
import { withHistory } from "slate-history";
import { IconButton } from "@mui/material";
import { getIcon, Leaf, toggleMark } from "./utils";
import {
  HOTKEYS,
  RenderElementProps,
  TemplateMdEditorProps,
} from "../../types/templates";
import { useTranslation } from "react-i18next";
import { ReactEditor } from "slate-react";

const withVariables = (editor: any) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element: any) =>
    element.type === "variable" || isInline(element);
  editor.isVoid = (element: any) =>
    element.type === "variable" || isVoid(element);

  return editor;
};

const TemplateMdEditor: React.FC<TemplateMdEditorProps> = ({
  subject,
  content,
  variables,
  placeholder,
  subjectPlaceholder,
  onChange,
  onSubjectChange,
}) => {
  const initialValue: Descendant[] = useMemo(
    () => JSON.parse(content),
    [content]
  );

  const initialSubjectValue: Descendant[] = useMemo(
    () => JSON.parse(subject || "[]"),
    [subject]
  );

  const editorSelectionRef = useRef<any>(null);
  const subjectSelectionRef = useRef<any>(null);

  const lastFocusedRef = useRef<"subject" | "editor">("editor");

  const { t } = useTranslation();

  const renderElement = useCallback(
    ({ attributes, children, element }: RenderElementProps) => {
      switch (element.type) {
        case "variable":
          return (
            <span
              {...attributes}
              contentEditable={false}
              style={{
                backgroundColor: "#5c5c5c",
                padding: "2px 4px",
                borderRadius: "4px",
                margin: "0 2px",
                display: "inline-block",
              }}
            >
              {children}
              {element.variableName}
            </span>
          );
        default:
          return <p {...attributes}>{children}</p>;
      }
    },
    []
  );

  const insertVariable = (
    variableName: string,
    editor: any,
    isSubject: boolean
  ) => {
    const variableElement = {
      type: "variable",
      variableName: `{{${variableName}}}`,
      children: [{ text: "" }],
      inline: true,
      void: true,
    };

    Transforms.insertNodes(editor, variableElement);

    if (isSubject && subjectSelectionRef.current) {
      ReactEditor.focus(editor);
      Transforms.select(editor, subjectSelectionRef.current);
    } else if (!isSubject && editorSelectionRef.current) {
      ReactEditor.focus(editor);
      Transforms.select(editor, editorSelectionRef.current);
    }
  };

  const handleEditorFocus = (editor: any, isSubject: boolean) => {
    lastFocusedRef.current = isSubject ? "subject" : "editor";
    if (isSubject && editor.selection) {
      subjectSelectionRef.current = editor.selection;
    } else if (editor.selection) {
      editorSelectionRef.current = editor.selection;
    }
  };

  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);

  const subjectEditor = useMemo(
    () => withVariables(withHistory(withReact(createEditor()))),
    []
  );

  const contentEditor = useMemo(
    () => withVariables(withHistory(withReact(createEditor()))),
    []
  );

  return (
    <div>
      <div className="flex gap-2 mb-2">
        {variables.map((variable) => (
          <IconButton
            key={variable}
            onMouseDown={(e) => {
              e.preventDefault(); // Prevent button click from stealing focus
            }}
            onClick={() => {
              if (lastFocusedRef.current === "subject") {
                insertVariable(variable, subjectEditor, true);
              } else {
                insertVariable(variable, contentEditor, false);
              }
            }}
          >
            {getIcon(variable)}
          </IconButton>
        ))}
      </div>

      {onSubjectChange && (
        <div>
          <label>{t("templates.subject")}</label>
          <Slate
            editor={subjectEditor}
            initialValue={initialSubjectValue}
            onChange={(value) => {
              const isAstChange = subjectEditor.operations.some(
                (op: any) => "set_selection" !== op.type
              );
              if (isAstChange) {
                onSubjectChange(JSON.stringify(value));
              }
            }}
          >
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={subjectPlaceholder}
              spellCheck
              autoFocus
              onFocus={() => handleEditorFocus(subjectEditor, true)} // Subject focus
            />
          </Slate>
        </div>
      )}

      <div className="mt-4 border-t pt-4">
        <label>{t("templates.content")}</label>
        <Slate
          editor={contentEditor}
          initialValue={initialValue}
          onChange={(value) => {
            const isAstChange = contentEditor.operations.some(
              (op: any) => "set_selection" !== op.type
            );
            if (isAstChange) {
              onChange(JSON.stringify(value));
            }
          }}
        >
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            spellCheck
            autoFocus
            onFocus={() => handleEditorFocus(contentEditor, false)} // Content focus
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
                  toggleMark(contentEditor, mark);
                }
              }
            }}
          />
        </Slate>
      </div>
    </div>
  );
};

export default TemplateMdEditor;
