import React from "react";
import CountryFlag from "react-country-flag";
import { OrderInfo } from "../../../types/orders";
import {
  formatOrderOperationTime,
  formatSingleTimeDisplay,
} from "../../../utils/time";
import { FaTrash, FaPencilAlt, FaCheckCircle } from "react-icons/fa";
import { FaMapLocation, FaFileInvoice, FaMessage } from "react-icons/fa6";
import StatusBadge from "../../../components/statusBadge/StatusBadge";
import { Link } from "react-router-dom";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../../utils/currencies";
import { useStrider } from "../../../contexts/StriderContext";
import CarrierSelect from "../../../components/carrierSelect/CarrierSelect";
import { useAuth } from "../../../contexts/AuthContext";
import NoData from "../../../components/NoData";
import { MdDisabledByDefault } from "react-icons/md";

interface OrdersProps {
  orders: OrderInfo[];
  ordersLoading: boolean;
  onDiscard: (orderId: number) => void;
  onUpdateOrder: (data: any, orderId: number) => void;
  onSelectCarrierForOrder: (carrierId: number, order: OrderInfo) => void;
  onSetOwnCarrierForOrder: (order: OrderInfo) => void;
}

const Orders: React.FC<OrdersProps> = ({
  orders,
  ordersLoading,
  onDiscard,
  onUpdateOrder,
  onSelectCarrierForOrder,
  onSetOwnCarrierForOrder,
}) => {
  const { t } = useTranslation();
  const { carriers, loading } = useStrider();
  const { company } = useAuth();

  if (!company) {
    return <NoData />;
  }

  return (
    <div className="overflow-x-auto w-full">
      <table className="bg-white dark:bg-zinc-800 shadow-xl rounded-2xl w-full">
        <thead className="bg-zinc-900 dark:bg-secondary dark:text-black text-secondary">
          <tr>
            <th className="px-4 py-2 text-left"></th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.shipper")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.carrier")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.loading")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.unloading")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.rate")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.refNo")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.truck")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.status")}
            </th>
            <th className="px-4 py-2 text-left">
              {t("orders.tableHeader.actions")}
            </th>
          </tr>
        </thead>
        {(ordersLoading || loading) ? (
          <tbody>
            <tr>
              <td colSpan={10} className="text-center py-4">
                <CircularProgress />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="text-gray-600 dark:text-white">
            {orders.map((order) => {
              return (
                <React.Fragment key={order.id}>
                  <tr
                    key={order.id}
                    className="border-b hover:bg-gray-300 transition duration-150 ease-in-out"
                  >
                    <td className="px-4 py-3 text-left">
                      {order.truck && (
                        <Link
                          to={`/map?order=${order.id}&truck=${order.truck.license_plate}`}
                        >
                          <FaMapLocation className="w-6 h-6" />
                        </Link>
                      )}
                    </td>
                    <td className="py-3 text-left text-xs max-w-xs overflow-hidden text-overflow-ellipsis ">
                      {order.shipper.name}
                    </td>
                    <td className="py-3 text-left">
                      <CarrierSelect
                        company={company}
                        carriers={carriers}
                        label={t("orders.selectCarrier")}
                        value={
                          order.own_order
                            ? `company-${company.id}`
                            : order.carrier?.id
                              ? `carrier-${order.carrier.id}`
                              : null
                        }
                        onChange={(value, isCompany) => {
                          if (isCompany) {
                            onSetOwnCarrierForOrder(order);
                          } else {
                            onSelectCarrierForOrder(value, order);
                          }
                        }}
                      />
                    </td>
                    <td className="px-1 py-3 text-left flex flex-col items-start  text-sm">
                      <div className="flex items-center space-x-1 font-bold">
                        <CountryFlag
                          countryCode={order.operations[0].country}
                          svg
                          style={{
                            width: "1em",
                            height: "1em",
                            borderRadius: "50%",
                          }}
                        />
                        <p className="uppercase">
                          {" "}
                          {order.operations[0].country}
                          {order.operations[0].postal_code.substring(0, 2)}
                        </p>
                        <p> {order.operations[0].locality}</p>
                      </div>
                      <p className="text-xs">{order.operations[0].address}</p>
                      {formatOrderOperationTime(order.operations[0])}
                    </td>
                    <td className="px-1 py-3 text-left text-sm">
                      <div className="flex items-center space-x-1 font-bold">
                        <CountryFlag
                          countryCode={
                            order.operations[order.operations.length - 1]
                              .country
                          }
                          svg
                          style={{
                            width: "1em",
                            height: "1em",
                            borderRadius: "50%",
                          }}
                        />
                        <p className="uppercase">
                          {
                            order.operations[order.operations.length - 1]
                              .country
                          }
                          {order.operations[
                            order.operations.length - 1
                          ].postal_code.substring(0, 2)}
                        </p>
                        <p>
                          {
                            order.operations[order.operations.length - 1]
                              .locality
                          }
                        </p>
                      </div>
                      <p className="text-xs">
                        {order.operations[order.operations.length - 1].address}
                      </p>

                      {formatOrderOperationTime(
                        order.operations[order.operations.length - 1]
                      )}
                    </td>
                    <td className="px-4 py-3  text-left">
                      <span className="font-semibold text-sm italic">
                        {order.payment.price_value.toFixed(2)}
                        {getCurrencySymbol(order.payment.price_currency)}
                      </span>

                      {order.estimated_distance && (
                        <>
                          <p className="text-xs">
                            {order.estimated_distance} km
                          </p>
                          <p className="text-xs">
                            {(
                              order.payment.price_value /
                              order.estimated_distance
                            ).toFixed(2)}{" "}
                            {getCurrencySymbol(order.payment.price_currency)}/km
                          </p>
                        </>
                      )}
                    </td>
                    <td className="px-4 py-3  text-left flex flex-col text-xs">
                      <div className="text-accent font-semibold">
                        {order.trans_ref_number}
                      </div>
                      <div className="">
                        {formatSingleTimeDisplay(
                          order.trans_date ? order.trans_date : order.created_at
                        )}
                      </div>
                      {order.ref_number && (
                        <div className="text-accent font-semibold">
                          {order.ref_number}
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-3  text-left min-w-44">
                      <TextField
                        select
                        label={t("orders.selectTruck")}
                        value={order.truck ? order.truck.license_plate : ""}
                        onChange={(e) =>
                          onUpdateOrder(
                            { license_plate: e.target.value },
                            order.id
                          )
                        }
                        variant="outlined"
                        fullWidth
                        size="small"
                        disabled={
                          order.carrier === null && order.own_order === false
                        }
                      >
                        {(order.own_order
                          ? company.trucks
                          : order.carrier
                            ? order.carrier.trucks
                            : []
                        ).map((truck) => (
                          <MenuItem
                            key={truck.license_plate}
                            value={truck.license_plate}
                          >
                            {truck.license_plate}
                          </MenuItem>
                        ))}
                      </TextField>
                    </td>

                    <td className="px-4 py-3 text-left flex flex-col space-y-2 text-xs">
                      <div className="flex items-center space-x-2">
                        <StatusBadge status={order.status} />
                      </div>

                      {order.invoice && (
                        <div className="">
                          <a
                            href={order.invoice.file}
                            target="_blank"
                            rel="noreferrer"
                            className="text-accent hover:underline hover:text-blue-800 flex items-center space-x-2"
                          >                          <FaFileInvoice className="text-lg text-blue-600" />
                            <span>View Invoice</span>
                          </a>
                        </div>
                      )}

                      <div className="flex items-center space-x-2">
                        <span className="text-gray-700 font-semibold">Transport File:</span>
                        {order.transport_order_file_uploaded ? (
                          <div className="flex items-center space-x-1 text-green-500">
                            <FaCheckCircle className="text-lg" />
                            <span>Uploaded</span>
                          </div>
                        ) : (
                          <div className="flex items-center space-x-1 text-red-500">
                            <MdDisabledByDefault className="text-lg" />
                            <span>Not Uploaded</span>
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="px-4 py-3">
                      <div className="flex items-center">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            onDiscard(order.id);
                          }}
                        >
                          <FaTrash className="h-4 w-4 text-accent" />
                        </IconButton>
                        <Link to={`/orders/${order.id}`}>
                          <button className="text-white font-bold py-1 px-4 rounded text-sm bg-danger">
                            <FaPencilAlt className="h-4 w-4 text-accent" />
                          </button>
                        </Link>
                        <Link to={`/orders/${order.id}/tracking`}>
                          <FaMessage className="h-4 w-4 text-accent" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default Orders;
