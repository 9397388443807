import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddEmployeeForm from "./AddEmployeeForm";
import { Employee } from "../../types/employees";
import useAxios from "../../hooks/useAxios";
import { useToast } from "../../hooks/useToast";
import { FaEdit } from "react-icons/fa";

interface EmployeesProps {
  link: string;
}

const Employees: React.FC<EmployeesProps> = ({ link }) => {
  const { t } = useTranslation();
  const [isOpen, setIsModalOpen] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(false);
  const axios = useAxios();
  const toast = useToast();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(link);
        setEmployees(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchEmployees();
  }, [link]);

  const onAddEmployee = async (data: any) => {
    setLoading(true);
    try {
      const response = await axios.post(link, data);
      const newEmployee = response.data;
      setEmployees((prevEmployees) => [...prevEmployees, newEmployee]);
      toast({
        message: "Employee added",
        type: "success",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddEmployee = async (data: any) => {
    await onAddEmployee(data);
    setIsModalOpen(false);
  };

  const handleDialogClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="dark:bg-zinc-800 p-4 border border-gray-400 text-black dark:text-white">
      <div className="flex justify-between items-center">
        <Typography variant="h5" component="h2" gutterBottom>
          {t("employeeList.title")}
        </Typography>
        <div className="mb-4">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <span className="mr-2">+</span>
            {t("employeeList.addEmployee")}
          </Button>
        </div>{" "}
      </div>

      {employees.length > 0 ? (
        <table className="bg-white dark:bg-zinc-800 shadow-xl rounded-2xl w-full">
          <thead className="bg-zinc-900 dark:bg-secondary dark:text-black text-secondary">
            <th className="px-4 py-2 text-left" style={{ width: "45%" }}>
              {t("employeeList.tableHeader.name")}
            </th>
            <th className="px-4 py-2 text-left" style={{ width: "45%" }}>
              {t("employeeList.tableHeader.email")}
            </th>
            <th className="px-4 py-2 text-left" style={{ width: "10%" }}>
            </th>
          </thead>
          <tbody>
            {employees.map((employee) => (
              <tr
                key={employee.id}
                className="border-b hover:bg-gray-300 transition duration-150 ease-in-out"
              >
                <td className="px-1 py-3 text-left text-sm">
                  {employee.full_name}
                </td>
                <td className="px-1 py-3 text-left text-sm">
                  {employee.email}
                </td>
                <td className="px-1 py-3 text-left text-sm">
                  <Link
                    to={`${link}/${employee.id}`}
                    className="flex items-center justify-center"
                  >
                    <IconButton>
                      <FaEdit />
                    </IconButton>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Typography variant="body1">{t("employeeList.noEmployees")}</Typography>
      )}
      <Dialog open={isOpen} onClose={handleDialogClose}>
        <DialogTitle>{t("employeeList.addEmployeeModal.title")}</DialogTitle>
        <DialogContent style={{ width: "600px" }}>
          <AddEmployeeForm onSubmit={handleAddEmployee} loading={loading} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Employees;
