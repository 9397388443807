import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import { Button } from "@mui/material";

const ExportInvoices = () => {
  const axios = useAxios();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!sellDateStart || !sellDateEnd) {
      setError(t("invoices.dateRequired"));
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get("/invoices/export", {
        params: {
          sell_date_start: sellDateStart.toISOString().split("T")[0],
          sell_date_end: sellDateEnd.toISOString().split("T")[0],
        },
        responseType: "blob", // Important to handle file response
      });

      // Create a blob URL and trigger download
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      const filename = `invoices_${sellDateStart.toISOString().split("T")[0]}_${
        sellDateEnd.toISOString().split("T")[0]
      }.pdf`;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      // if status 404 - then display no invoices
      if (err.response?.status === 404) {
        setError(t("invoices.noInvoices"));
      } else {
        setError(t("invoices.exportError"));
      }
    } finally {
      setLoading(false);
    }
  };

  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1);
  startDate.setDate(1);

  const endDate = new Date();
  endDate.setMonth(endDate.getMonth(), 0);

  const [sellDateStart, setSellDateStart] = useState<Date>(startDate);
  const [sellDateEnd, setSellDateEnd] = useState<Date>(endDate);

  return (
    <div className="p-6 w-full max-w-md">
      <h2 className="text-2xl font-bold mb-4">
        {t("invoices.exportInvoices")}
      </h2>
      {error && (
        <div className="bg-red-100 text-red-700 p-2 rounded mb-4">{error}</div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex gap-2">
          {" "}
          <DatePicker
            label={t("invoices.sellDateStart")}
            value={sellDateStart}
            format="yyyy-MM-dd"
            onChange={(date) => {
              if (date) {
                setSellDateStart(date);
              }
            }}
          />
          <DatePicker
            label={t("invoices.sellDateEnd")}
            value={sellDateEnd}
            format="yyyy-MM-dd"
            onChange={(date) => {
              if (date) {
                setSellDateEnd(date);
              }
            }}
          />
        </div>

        <div>
          <Button
            type="submit"
            disabled={loading}
            variant="contained"
            color="primary"
            fullWidth
          >
            {loading ? t("invoices.loading") : t("invoices.export")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ExportInvoices;
