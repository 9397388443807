import React, { useEffect, useState } from "react";
import { BaseTruck } from "../../types/truck";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, Dialog } from "@mui/material";
import { useToast } from "../../hooks/useToast";
import useAxios from "../../hooks/useAxios";
import AddTruckForm from "./AddTruckForm";
import NoData from "../NoData";

type Props = {
  link: string;
};

const TruckList: React.FC<Props> = ({ link }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trucks, setTrucks] = useState<BaseTruck[]>([]);
  const axios = useAxios();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onAddTruck = (data: any) => {
    axios
      .post(link, {
        ...data,
      })
      .then((response) => {
        setIsModalOpen(false);
        setTrucks((prevTrucks) => [...prevTrucks, response.data]);
        toast({
          message: "Truck added",
          type: "success",
        });
      })
      .catch((error) => {
        console.error(error);
        toast({
          message: "Failed to add truck",
          type: "error",
        });
      });
  };

  useEffect(() => {
    const fetchTrucks = async () => {
      try {
        setLoading(true);
        const response = await axios.get(link);
        setTrucks(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrucks();
  }, [link]);

  return (
    <div className="p-4 border border-gray-400 text-black dark:text-white">
      <div className="flex justify-between items-center my-2">
        {" "}
        <h2 className="text-xl font-semibold">{t("truckList.title")}</h2>
        <Button variant="contained" color="primary" onClick={() => setIsModalOpen(true)}>
          <span className="mr-2">+</span>
          {t("truckList.addTruck")}
        </Button>
      </div>
      {loading && <CircularProgress />}
      {!loading && trucks.length === 0 && <NoData />}
      {!loading && trucks.length > 0 && (
        <table className="bg-white dark:bg-zinc-800 shadow-xl rounded-2xl w-full my-2">
          <thead className="bg-zinc-900 dark:bg-secondary dark:text-black text-secondary">
          <tr>
              <th className="px-4 py-2 text-left">
                {t("truckList.tableHeader.name")}
              </th>
              <th className="px-4 py-2 text-left">
                {t("truckList.tableHeader.type")}
              </th>
              <th className="px-4 py-2 text-left">
                {t("truckList.tableHeader.licensePlate")}
              </th>
              <th className="px-4 py-2 text-left">
                {t("truckList.tableHeader.mileage")}
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-600 dark:text-white">
            {trucks.map((truck) => (
              <tr
                key={truck.license_plate}
                className="border-b hover:bg-gray-300 transition duration-150 ease-in-out cursor-pointer"
                onClick={() => {
                  navigate(`${link}/${truck.license_plate}`);
                }}
              >
                <td className="px-4 py-3 text-left text-xs max-w-xs overflow-hidden text-overflow-ellipsis whitespace-nowrap">
                  {truck.name}
                </td>
                <td className="px-4 py-3 text-left text-xs max-w-xs overflow-hidden text-overflow-ellipsis whitespace-nowrap">
                  {truck.parameters?.truck_sub_type} - {truck.gvm}
                </td>
                <td className="px-4 py-3 text-left text-xs max-w-xs overflow-hidden text-overflow-ellipsis whitespace-nowrap">
                  {truck.license_plate}
                </td>
                <td className="px-4 py-3 text-left text-xs max-w-xs overflow-hidden text-overflow-ellipsis whitespace-nowrap">
                  {truck.mileage}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Dialog
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <AddTruckForm onSubmit={onAddTruck} />
      </Dialog>
    </div>
  );
};

export default TruckList;
