import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaRoute,
  FaLink,
  FaUser,
  FaChartLine,
  FaFileInvoice,
  FaShippingFast,
  FaStore,
  FaExchangeAlt,
} from "react-icons/fa";
import { FiGrid } from "react-icons/fi";
import { MdAssignment, MdAttachMoney, MdWork } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { IoIosSunny, IoIosMoon } from "react-icons/io";

interface MenuItemProps {
  title: string;
  path?: string;
  selected?: boolean;
  IconComponent: React.ElementType;
}

import { hasAccess } from "../../utils/permissions";
import { useAuth } from "../../contexts/AuthContext";
import { CircularProgress, Divider, IconButton } from "@mui/material";
import { useTheme } from "../../contexts/ThemeContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import { HiTemplate } from "react-icons/hi";

const MenuItem: React.FC<MenuItemProps> = ({
  title,
  path = undefined,
  selected = false,
  IconComponent,
}) => {
  return (
    <Link to={path || "#"}>
      <div
        className={`px-4 py-2 rounded-lg flex items-center text-black hover:text-gray-400 dark:text-secondary
        ${selected ? "bg-accent cursor-default" : "hover:bg-gray-900"}
        `}
      >
        <IconComponent /> <span className="ml-2 text-xs">{title}</span>
      </div>
    </Link>
  );
};

const SideMenu: React.FC = () => {
  const { darkMode, toggleDarkMode } = useTheme();
  const location = useLocation();
  const { t } = useTranslation();
  const { account, accountLoading } = useAuth();

  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const [hidden, setHidden] = useLocalStorage("hidden", false);

  const hiddenParams = new URLSearchParams(location.search).get("hidden");

  useEffect(() => {
    if (hiddenParams === "false") {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }, [hiddenParams]);

  useEffect(() => {
    const path = location.pathname.split("/");
    if (path.length > 1) {
      setSelectedItem(path[1]);
    } else {
      setSelectedItem(null);
    }
  }, [location]);

  if (accountLoading || !account) {
    return (
      <div className="w-18 min-h-screen p-6 bg-secondary dark:bg-zinc-900">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="w-48 min-h-screen max-h-screen p-4 bg-secondary dark:bg-zinc-900">
      <div className="fixed w-36 h-full flex items-center">
        {" "}
        <div className="flex flex-col space-y-2 bg-neutral-300 dark:bg-zinc-800 rounded-xl p-2">
          {!hidden && (
            <>
              <MenuItem
                title="Exchange"
                selected={selectedItem === "exchange"}
                path={`/exchange`}
                IconComponent={FaExchangeAlt}
              />
              <Divider />
            </>
          )}
          <MenuItem
            title={t("menu.dashboard")}
            selected={selectedItem === ""}
            path={`/`}
            IconComponent={FiGrid}
          />
          <MenuItem
            title={t("menu.orders")}
            selected={selectedItem === "orders"}
            path={`/orders`}
            IconComponent={MdAssignment}
          />
          <MenuItem
            title={t("menu.map")}
            selected={selectedItem === "map"}
            path={`/map`}
            IconComponent={FaRoute}
          />
          <MenuItem
            title={t("menu.myFleet")}
            selected={selectedItem === "my-fleet"}
            path={`/my-fleet`}
            IconComponent={FaShippingFast}
          />
          <MenuItem
            title={t("menu.shippers")}
            selected={selectedItem === "shippers"}
            path={`/shippers`}
            IconComponent={FaStore}
          />
          <MenuItem
            title={t("menu.carriers")}
            selected={selectedItem === "carriers"}
            path={`/carriers`}
            IconComponent={IoIosPeople}
          />
          <MenuItem
            title={t("menu.invoices")}
            selected={selectedItem === "invoices"}
            path={`/invoices`}
            IconComponent={FaFileInvoice}
          />
          <MenuItem
            title={t("menu.delegations")}
            selected={selectedItem === "delegations"}
            path={`/delegations`}
            IconComponent={MdWork}
          />
          {hasAccess(account.role, "insights", "read") && (
            <MenuItem
              title={t("menu.insights")}
              selected={selectedItem === "insights"}
              path={`/insights`}
              IconComponent={FaChartLine}
            />
          )}
          <MenuItem
            title={t("menu.integrations")}
            selected={selectedItem === "integrations"}
            path={`/integrations`}
            IconComponent={FaLink}
          />
          <MenuItem
            title={t("menu.costs")}
            selected={selectedItem === "costs"}
            path={`/costs`}
            IconComponent={MdAttachMoney}
          />{" "}
          <MenuItem
            title={t("menu.templates")}
            selected={selectedItem === "templates"}
            path={`/templates`}
            IconComponent={HiTemplate}
          />
          <MenuItem
            title={t("menu.account")}
            selected={selectedItem === "account"}
            path={`/account`}
            IconComponent={FaUser}
          />{" "}
          <div className="mx-auto flex items-center">
            <IconButton
              onClick={() => toggleDarkMode()}
              sx={{
                margin: "auto",
              }}
            >
              {darkMode && <IoIosSunny className="text-yellow-500" />}
              {!darkMode && <IoIosMoon className="text-zinc-400" />}
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
