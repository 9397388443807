import { TextField, MenuItem, Button, Avatar } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE, LANGUAGES } from "../../consts/settings";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import DragDropInput from "../../components/input/DragDropInput";
import { useToast } from "../../hooks/useToast";

interface AccountDetailsFormProps {
  onSubmit: (data: any) => void;
}

interface AccountFormData {
  preferences: {
    language: string;
  };
}

const AccountDetailsForm: React.FC<AccountDetailsFormProps> = ({
  onSubmit,
}) => {
  const toast = useToast();
  const { accountLoading, account } = useAuth();
  const { t } = useTranslation();
  const { handleSubmit, control, setValue } = useForm<AccountFormData>({
    defaultValues: {
      preferences: {
        language: account?.preferences?.language || DEFAULT_LANGUAGE,
      },
    },
  });
  const [avatarUploading, setAvatarUploading] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState<string | null>(null);
  const [emailSignatureUploading, setEmailSignatureUploading] = useState(false);
  const [emailSignaturePreview, setEmailSignaturePreview] = useState<
    string | null
  >(null);

  const axios = useAxios();

  useEffect(() => {
    if (!accountLoading && account) {
      setValue(
        "preferences.language",
        account.preferences?.language || DEFAULT_LANGUAGE
      );
      if (account.preferences?.avatar) {
        setAvatarPreview(account.preferences.avatar);
      }
      if (account.email_signature) {
        setEmailSignaturePreview(account.email_signature);
      }
    }
  }, [accountLoading, account]);

  const handleAvatarChange = async (files: FileList) => {
    if (files.length === 0) return;

    const file = files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      setAvatarUploading(true);
      const fileContent = reader.result;
      const payload = {
        file: {
          name: file.name,
          base64: fileContent,
        },
      };
      try {
        const response = await axios.patch(
          "/account/profile/upload-avatar/",
          payload
        );
        setAvatarPreview(response.data.preferences.avatar);
      } catch (error) {
        console.error(error);
        toast({
          message: "Failed to add document",
          type: "error",
        });
      } finally {
        setAvatarUploading(false);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleSignatureChange = async (files: FileList) => {
    if (files.length === 0) return;

    const file = files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      setEmailSignatureUploading(true);
      const fileContent = reader.result;
      const payload = {
        email_signature_file: {
          name: file.name,
          base64: fileContent,
        },
      };
      try {
        const response = await axios.patch(
          "/account/profile/upload-signature/",
          payload
        );
        setEmailSignaturePreview(response.data.email_signature);
      } catch (error) {
        console.error(error);
        toast({
          message: "Failed to add signature",
          type: "error",
        });
      } finally {
        setEmailSignatureUploading(false);
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="p-4 text-black dark:text-white">
      <form
        className=" text-black p-16 flex flex-col gap-4 border-b border-black"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="text-2xl font-bold">
          {t("account.accountDetails.title")}
        </h1>
        <div className="">
          <Controller
            name="preferences.language"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label={t("account.accountDetails.language")}
                fullWidth
                variant="outlined"
              >
                {LANGUAGES.map((language) => (
                  <MenuItem key={language.code} value={language.code}>
                    <div className="flex items-center gap-2">
                      <ReactCountryFlag countryCode={language.country} svg />
                      {t(`common.languages.${language.code}`)}
                    </div>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </div>
        <Button variant="contained" color="primary" type="submit">
          {t("common.save")}
        </Button>
      </form>
      <div className="flex gap-4 mt-4 items-end justify-center">
        {" "}
        <div className="flex flex-col items-center gap-2 w-1/2">
          <h2 className="text-3xl font-bold">
            {t("account.accountDetails.avatar")}
          </h2>
          {avatarPreview && (
            <Avatar
              src={avatarPreview}
              alt="Avatar Preview"
              sx={{ width: 64, height: 64 }}
            />
          )}
          <div className="flex flex-col gap-2 w-1/2">
            <DragDropInput
              uploading={avatarUploading}
              label={`${t("account.accountDetails.maxFileSize")}: 1MB`}
              types={["png", "jpg", "jpeg", "svg"]}
              handleChange={handleAvatarChange}
              name={t("account.accountDetails.uploadNewAvatar")}
              multiple={true}
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-2">
          <h2 className="text-3xl font-bold">
            {t("account.accountDetails.emailSignature")}
          </h2>
          {emailSignaturePreview && (
            <img src={emailSignaturePreview} alt="Email Signature Preview" />
          )}
          <div className="flex flex-col gap-2">
            <DragDropInput
              uploading={emailSignatureUploading}
              label={`${t("account.accountDetails.maxFileSize")}: 1MB`}
              types={["png", "jpg", "jpeg", "svg"]}
              handleChange={handleSignatureChange}
              name={t("account.accountDetails.uploadNewSignature")}
              multiple={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetailsForm;
