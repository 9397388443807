import TemplateEditor from "./TemplateEditor";
import { useTranslation } from "react-i18next";

const STARTING_TEMPLATE = {
    id: 0,
    name: "",
    language: "en",
    subject: "[]",
    message: "[]",
};

interface CreateTemplateFormProps {
    variables: string[];
    onSave: (data: any) => void;
}

const CreateTemplateForm: React.FC<CreateTemplateFormProps> = ({variables, onSave}) => {


  const { t } = useTranslation();

  return (
    <>
      <TemplateEditor
        template={STARTING_TEMPLATE}
        placeholder={t("templates.placeholder")}
        subjectPlaceholder={t("templates.subjectPlaceholder")}
        variables={variables}
        handleSave={onSave}
        onPreview={() => {}}
      />
    </>
  );
};

export default CreateTemplateForm;
