import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import SideMenu from "../components/sideMenu/SideMenu";
import TopNavBar from "../components/navbar/Navbar";
import { CircularProgress } from "@mui/material";
import { hasAccess } from "../utils/permissions";
import { StriderProvider } from "../contexts/StriderContext";
import { ChatProvider } from "../contexts/ChatContext";
import { BankAccountsProvider } from "../contexts/BankAccountsContext";
import ChatContainer from "../components/chat/ChatContainer";

interface ProtectedRouteProps {
  requirePermission?: string;
  requireAction?: string;
  children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  requirePermission,
  requireAction,
}) => {
  const { accountLoading, token, account, company, companyLoading } = useAuth();

  const currentRoute = window.location.pathname.split("/")[1];

  if (!token) {
    return <Navigate to="/sign-in" />;
  }

  const isLoading = accountLoading || companyLoading;

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gradient-prime">
        <CircularProgress />
      </div>
    );
  }

  if (!account || !company) {
    return <Navigate to="/error" />;
  }

  if (
    !company.subscription ||
    !(company.subscription.is_active || company.subscription.is_in_trial)
  ) {
    return <Navigate to="/subscription" />;
  }

  if (
    requirePermission &&
    requireAction &&
    !hasAccess(account?.role, requirePermission, requireAction)
  ) {
    return <Navigate to="/" />;
  }

  return (
    <StriderProvider>
      <BankAccountsProvider>
        <ChatProvider>
          <div className="flex dark:bg-zinc-900 bg-secondary">
            <SideMenu />
            <div className="w-full h-full">
              {currentRoute !== "map" && <TopNavBar />}
              <div className="p-4 m-4 rounded-3xl dark:bg-zinc-800 bg-white shadow-lg">
              {children}
              </div>
            </div>
          </div>
          <ChatContainer />
        </ChatProvider>
      </BankAccountsProvider>
    </StriderProvider>
  );
};
